import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";

function BlockHeader(props) {
  return (
    <div>
      <h2 className="bloom-text-3xl md:bloom-text-4xl bloom-font-bold bloom-mb-10 bloom-text-primary-dark">
        {props.title}
      </h2>
    </div>
  );
}

export default BlockHeader;
