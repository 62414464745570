import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import BlockHeader from "./blockHeader";

function Block(props) {
  let style = {};

  if (props.bgImage) {
    style = {
      backgroundImage: `url(${props.bgImage})`,
      backgroundPosition: `bottom right`,
      backgroundRepeat: `no-repeat`,
      backgroundSize: `65%`
    };
  }

  return (
    <div
      className={props.className}
      id={
        props.id ||
        `block-${Math.random()
          .toString(36)
          .substring(2, 15) +
          Math.random()
            .toString(36)
            .substring(2, 15)}`
      }
      style={style}
    >
      <div
        className={`bloom-max-w-4xl bloom-container bloom-mx-auto bloom-py-8 md:bloom-py-14`}
      >
        {props.title && <BlockHeader title={props.title} />}
        {props.children}
      </div>
    </div>
  );
}

export default Block;
